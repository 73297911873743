const deutsch = {
    'USER.Button_Text_Create': 'Nutzer anlegen',
    'USER.Button_Text_Delete': 'Nutzer löschen',
    'USER.Button_Text_Import': 'Benutzerimport',
    'COMPANY.Button_Text_Create': 'Unternehmen erstellen',
    'COMPANY.Button_Text_Delete': 'Firma löschen',
    'SELLER.Button_Text_AddSeller': 'Verkäufer hinzufügen',
    'SELLER.Button_Text_Delete': 'Verkäufer löschen',
    'DEALER.Button_Text_Create': 'Händler anlegen',
    'DEALER.Button_Text_Delete': 'Händler löschen',
    'POST.Button_Text_Delete': 'Post löschen',
    'TABLES.Column_Title_ID': 'ID',
    'TABLES.Column_Title_Title': 'Überschrift',
    'TABLES.Column_Title_Author': 'Autor',
    'TABLES.Column_Title_Likes': 'Likes',
    'TABLES.Column_Title_Comments': 'Kommentar',
    'TABLES.Column_Title_Media': 'Medien',
    'TABLES.Column_Title_Attachments': 'Anhang',
    'TABLES.Column_Title_Name': 'Name',
    'TABLES.Column_Title_Phone': 'Telefon',
    'TABLES.Column_Title_Email': 'E-Mail',
    'TABLES.Column_Title_User': 'Benutzer',
    'TABLES.Column_Title_Type': 'Typ',
    'TABLES.Column_Title_Message': 'Mitteilung',
    'TABLES.Column_Title_Label': 'Label',
    'TABLES.Column_Title_Posts': 'Posts',
    'TABLES.Column_Title_Followers': 'Followers',
    'TABLES.Column_Title_CreateDate': 'Erstellungsdatum',
    'TABLES.Column_Sorting_Tooltip_Ascending': 'Klick für aufsteigende Sortierung',
    'TABLES.Column_Sorting_Tooltip_Descending': 'Klick für absteigende Sortierung',
    'TABLES.Column_Sorting_Tooltip_Cancel': 'Klick um Sortierung abzubrechen',
    'TABLES.Column_Title_DealerNumber': 'Händlernummer',
    'TABLES.Column_Title_Dealer': 'Händler',
    'TABLES.Column_Title_CompanyName': 'Unternehmensname',
    'TABLES.Column_Title_City': 'Stadt',
    'TABLES.Column_Title_Street': 'Strasse',
    'TABLES.Column_Title_ZipCode': 'PLZ',
    'TABLES.Column_Title_Region': 'Region',
    'TABLES.Column_Title_District': 'Gebiet',
    'TABLES.Column_Title_FullName': 'Vollständiger Name',
    'TABLES.Column_Title_Username': 'Nutzername',
    'TABLES.Column_Title_Contacts': 'Kontakte',
    'TABLES.Column_Title_SellerNumber': 'VerkäuferNr.',
    'TABLES.Column_Title_Role': 'Nutzer Rolle',
    'TABLES.Column_Title_Status': 'Status',
    'TABLES.Column_Title_Total': 'Summe',
    'TABLES.Column_Title_Year': 'Jahr',
    'TABLES.Column_Title_FileName': 'Dateiname',
    'TABLES.Column_Title_Description': 'Beschreibung',
    'TABLES.Column_Title_Asked': 'Fragte',
    'TABLES.Column_Title_Question': 'Frage',
    'TABLES.Column_Title_Answered': 'Antwortete',
    'TABLES.Column_Title_Answer': 'Antwort',
    'TABLES.Column_Title_QuestionDate': 'Fragedatum',
    'TABLES.Column_Title_PostTitle': 'Posttitel',
    'TABLES.Column_Title_LastVisit': 'Letzter Besuch',
    'TABLES.Column_Title_CompanyLogo': 'Firmenlogo',
    'TABLES.Column_Title_FullCompanyName': 'Vollständiger Firmenname',
    'TABLES.Column_Title_ShortName': 'Kurzer Name',
    'TABLES.Column_Title_LinkMainSite': 'Link zur Hauptseite',
    'TABLES.Column_Title_ImportNum': 'Importnr',
    'TABLES.Column_Title_PostDescr': 'Beitragsbeschreibung',
    'TABLES.Column_Title_DiamondStatus': 'Diamant-Status',
    'FORM_RULES.Min_NewPassword': 'Das Passwort muss mindestens { minLength } Zeichen enthalten',
    'FORM_RULES.Required_NewPassword': 'Bitte neues Passwort eingeben',
    'FORM_RULES.Required_ConfirmPassword': 'Bitte neues Passwort bestätigen',
    'FORM_RULES.Required_ConfirmPasswordMatch': 'Die eingegebenen Passwörter stimmen nicht überein',
    'FORM_RULES.Required_Name': 'Bitte Vornamen eingeben',
    'FORM_RULES.Required_Surname': 'Bitte Nachnamen eingeben',
    'FORM_RULES.Required_Email': 'Die Eingabe ist eine ungültige E-Mail',
    'FORM_RULES.Required_Choose': 'Bitte auswählen',
    'FORM_RULES.Required_Password': 'Bitte Passwort eingeben',
    'FORM_RULES.Required_FullName': 'Bitte den Vor- und Nachnamen eingeben',
    'FORM_RULES.Required_FullNameMinChar': 'Der vollständige Name muss aus mindestens 3 Zeichen bestehen',
    'FORM_RULES.Required_UserName': 'Nutzername',
    'FORM_RULES.Required_SellerNumber': 'Verkäufernummer',
    'FORM_RULES.Required_CompanyName': 'Unternehmensname',
    'FORM_RULES.Required_DealerNumber': 'Händlernummer',
    'FORM_RULES.Required_FullCompanyName': 'Vollständiger Firmenname',
    'FORMS.Input_Label_Password': 'Passwort',
    'FORMS.Input_Label_Login': 'Anmelden',
    'FORMS.Input_Label_NewPassword': 'neues Passwort',
    'FORMS.Input_Label_ConfirmPassword': 'neues Passwort bestätigen',
    'FORMS.Input_Label_Language': 'Sprache',
    'FORMS.Input_Label_Name': 'Name',
    'FORMS.Input_Label_UserName': 'Nutzername',
    'FORMS.Input_Label_Surname': 'Nachname',
    'FORMS.Input_Label_Phone': 'Handynummer',
    'FORMS.Input_Label_Email': 'E-Mail',
    'FORMS.Input_Label_Attachment': 'Anhang',
    'FORMS.Input_Label_Title': 'Überschrift',
    'FORMS.Input_Label_CollectionName': 'Name der Kollektion',
    'FORMS.Input_Label_CollectionUniqName': 'einmaliger Name der Kollektion',
    'FORMS.Input_Label_Number': 'Nummer',
    'FORMS.Input_Label_Time': 'Zeit',
    'FORMS.Input_Label_Description': 'Beschreibung',
    'FORMS.Input_Label_ChooseType': 'Typ auswählen',
    'FORMS.Input_Label_Status': 'Status',
    'FORMS.Input_Label_ChooseStatus': 'Status auswählen',
    'FORMS.Input_Label_CompanyName': 'Unternehmensname',
    'FORMS.Input_Label_DealerNumber': 'Händlernummer',
    'FORMS.Input_Label_Dealer': 'Händler',
    'FORMS.Input_Label_City': 'Stadt',
    'FORMS.Input_Label_ZipCode': 'PLZ',
    'FORMS.Input_Label_Street': 'Strasse',
    'FORMS.Input_Label_Region': 'Region',
    'FORMS.Input_Label_District': 'Gebiet',
    'FORMS.Input_Label_Gender': 'Geschlecht',
    'FORMS.Input_Label_Role': 'Nutzer Rolle',
    'FORMS.Input_Label_Salutation': 'Anrede',
    'FORMS.Input_Label_JobType': 'Stellenbezeichnung',
    'FORMS.Input_Label_SellerNumber': 'Verkäufernummer',
    'FORMS.Input_Label_Seller': 'Verkäufer',
    'FORMS.Input_Label_Year': 'Jahr',
    'FORMS.Input_Label_User': 'Nutzer',
    'FORMS.Input_Label_Author': 'Autor',
    'FORMS.Input_Label_CreatedAt': 'erstellt am',
    'FORMS.Input_Label_ActivationByEmail': 'Kontoaktivierung per E-Mail',
    'FORMS.Input_Label_RegistrationByEmail': 'Anmeldung per E-Mail',
    'FORMS.Input_Label_AllowedEmail': 'genemigte E-Mail',
    'FORMS.Input_Label_AllowedPersonalEmail': 'genemigte persönliche E-Mail',
    'FORMS.Input_Label_Answer': 'Antwort',
    'FORMS.Input_Label_Question': 'Frage',
    'FORMS.Input_Label_DateBirth': 'Geb.- Tag',
    'FORMS.Input_Label_DateEntrance': 'Eintrittsdatum ',
    'FORMS.Input_Label_VisitedSite': 'Besuchte Seite',
    'FORMS.Input_Label_Period': 'Zeitraum',
    'FORMS.Input_Label_FullCompanyName': 'Vollständiger Firmenname',
    'FORMS.Input_Label_ShortName': 'Kurzer Name',
    'FORMS.Input_Label_LinkMainSite': 'Link zur Hauptseite',
    'FORMS.Input_Label_ImportNumber': 'Nummer importieren',
    'FORMS.Input_Label_Logo': 'Logo',
    'FORMS.Input_Label_AssignedCompanies': 'Zugewiesene Unternehmen',
    'FORMS.Input_Label_DiamondStatus': 'Diamant-Status',
    'FORMS.Title_SignIn': 'Anmelden',
    'FORMS.Title_ForgotPassword': 'Passwort vergessen',
    'FORMS.Title_ChangePassword': 'Passwort ändern',
    'FORMS.Title_SendPassword': 'Passwort senden',
    'FORMS.Title_SendMail': 'Willkommens-E-Mail senden',
    'FORMS.Title_AddUsers': 'Nutzer hinzufügen',
    'FORMS.Title_Text_Filters': 'Filter',
    'FORMS.Title_AddFile': 'Datei hinzufügen',
    'FORMS.Title_EditFile': 'Datei bearbeiten',
    'FORMS.Title_ChooseDealer': 'Händler auswählen',
    'FORMS.Title_ChooseUser': 'Nutzer auswählen',
    'FORMS.Title_AddSeller': 'Verkäufer hinzufügen',
    'FORMS.Title_EditSeller': 'Verkäufer bearbeiten',
    'FORMS.Title_Question': 'Benutzerfragen',
    'FORMS.Title_Answer': 'Antwort auf die Frage',
    'FORMS.Title_UserStatistic': 'Benutzerstatistiken',
    'FORMS.Title_AddCompanies': 'Firmen hinzufügen',
    'FORMS.Select_OptionLabel_NotSelected': 'Nicht ausgewählt',
    'FORMS.Select_OptionLabel_English': 'Englisch',
    'FORMS.Select_OptionLabel_Deutsch': 'Deutsch',
    'FORMS.Select_OptionLabel_Male': 'männlich',
    'FORMS.Select_OptionLabel_Female': 'weiblich',
    'FORMS.Select_OptionLabel_User': 'Nutzer',
    'FORMS.Select_OptionLabel_Moderator': 'Moderator',
    'FORMS.Select_OptionLabel_Admin': 'Administrator',
    'FORMS.Select_OptionLabel_Mrs': 'Frau',
    'FORMS.Select_OptionLabel_Mr': 'Herr',
    'FORMS.Select_OptionLabel_Active': 'Aktiv',
    'FORMS.Select_OptionLabel_Inactive': 'Inaktiv',
    'FORMS.Select_OptionLabel_Block': 'Gesperrt',
    'FORMS.Select_OptionLabel_Public': 'Öffentlich',
    'FORMS.Select_OptionLabel_Trash': 'Müll',
    'FORMS.Select_OptionLabel_Private': 'Privat',
    'FORMS.Select_OptionLabel_Draft': 'Entwurf',
    'FORMS.Select_OptionLabel_CEO': 'Geschäftsführer',
    'FORMS.Select_OptionLabel_CSellingOwner': 'Mitverkaufender Inhaber',
    'FORMS.Select_OptionLabel_Marketing': 'Marketing',
    'FORMS.Select_OptionLabel_BranchManager': 'Filialleiter / Markenverantwortlicher',
    'FORMS.Select_OptionLabel_SalesManager': 'Verkaufsleiter',
    'FORMS.Select_OptionLabel_CertifiedCarSalesman': 'Geprüfter Automobilverkäufer',
    'FORMS.Select_OptionLabel_Salesperson': 'Verkäufer / Büropersonal Verkauf / M-Sales Verkäufer',
    'FORMS.Select_OptionLabel_ServiceConsultant': 'Serviceberater',
    'FORMS.Select_OptionLabel_OwnerShareholder': 'Inhaber / Gesellschafter',
    'FORMS.Select_OptionLabel_CarMaster': 'KFZ Meister',
    'FORMS.Select_OptionLabel_Any': 'Alle',
    'FORMS.Select_OptionLabel_Yes': 'Ja',
    'FORMS.Select_OptionLabel_No': 'Nein',
    'FORMS.Select_OptionLabel_NotAssigned' : 'Nicht zugewiesen',
    'FORMS.Input_Placeholder_Email': 'E-MAil',
    'FORMS.Input_Placeholder_Choose': 'Bitte auswählen',
    'FORMS.Input_Placeholder_Name': 'Name',
    'FORMS.Input_Placeholder_SelectDate': 'Datum auswählen',
    'FORMS.Input_Placeholder_ShortDescription': 'Kurzbeschreibung',
    'FORMS.Input_Placeholder_ChooseStatus': 'Status auswählen',
    'FORMS.Input_Placeholder_ChooseType': 'Typ auswählen',
    'FORMS.Input_Placeholder_NotSelected': 'nicht ausgewählt',
    'FORMS.Input_Placeholder_Password': 'Passwort',
    'FORMS.Input_Placeholder_Year': 'Jahr auswählen',
    'FORMS.Input_Placeholder_SellerNumber': 'Verkäufernummer eingeben',
    'FORMS.Input_Placeholder_StartDate': 'Start Datum',
    'FORMS.Input_Placeholder_EndDate': 'End Datum',
    'FORMS.Input_Placeholder_NotAssigned' : 'Nicht zugewiesen',
    'FORMS.Input_Placeholder_PleaseChose' : 'Auswählen',
    'FORMS.Button_Text_ApplyFilters': 'Filter anwenden',
    'FORMS.Button_Text_ResetFilters': 'Filter löschen',
    'FORMS.Button_Text_Apply': 'Anwenden',
    'FORMS.Button_Text_UpdateCount': 'Konto aktualisieren',
    'SEARCH.Input_Placeholder_Table_Dealer': 'Suche nach Unternehmensname, Händernummer, Stadt, Strasse, PLZ',
    'SEARCH.Input_Placeholder_Table_User': 'Suche nach Name, E-Mail, Telefonnummer',
    'SEARCH.Input_Placeholder_Table_Title': 'Suche nach Überschrift',
    'SEARCH.Input_Placeholder_MultiSelect': 'Suche',
    'SEARCH.Input_Placeholder_User': 'Suche nach Nutzer',
    'SEARCH.Input_Placeholder_Dealer': 'Händler',
    'SEARCH.Input_Placeholder_Seller': 'Such nach Verkäufer',
    'GLOBAL.Button_Text_Save': 'Speichern',
    'GLOBAL.Button_Text_Send': 'Senden',
    'GLOBAL.Button_Text_SaveSend': 'Speichern & Senden',
    'GLOBAL.Button_Text_SaveChanges': 'Änderungen speichern',
    'GLOBAL.Button_Text_Edit': 'Bearbeiten',
    'GLOBAL.Button_Text_Update': 'Aktualisieren',
    'GLOBAL.Button_Text_Reimport': 'neu importieren',
    'GLOBAL.Button_Text_Cancel': 'Abrechen',
    'GLOBAL.Button_Text_Delete': 'Löschen',
    'GLOBAL.Button_Text_Remove': 'Entfernen',
    'GLOBAL.Button_Text_Confirm': 'Bestätigen',
    'GLOBAL.Button_Text_AddFile': 'Datei hinzufügen',
    'GLOBAL.Button_Text_UploadFile': 'Datei hochladen',
    'GLOBAL.Button_Text_Login': 'Anmelden',
    'GLOBAL.Button_Text_BackHome': 'zurück zu Start',
    'GLOBAL.Button_Text_Actions': 'Aktion',
    'GLOBAL.Modal_Text_UserSendPass': 'Sind Sie sicher, dass Sie ein neues Passwort senden möchten?',
    'GLOBAL.Modal_Text_UserSendMail': 'Sind Sie sicher, dass Sie eine Willkommens-E-Mail senden möchten?',
    'GLOBAL.Modal_Text_RemoveText': 'Sind Sie sicher, dass Sie { deleteObjectType } entfernen wollen?',
    'GLOBAL.Modal_Text_DeleteText': 'Sind Sie sicher, dass Sie { deleteObjectType } löschen wollen?',
    'GLOBAL.Link_Text_Download': 'Runterladen',
    'GLOBAL.Link_Text_Unassigned': 'nicht zugewiesen',
    'GLOBAL.Link_Text_Assigned': 'zugewiesen',
    'GLOBAL.Text_NoData': 'keine Daten',
    'GLOBAL.Text_Status_Disabled': 'deaktiviert',
    'GLOBAL.Text_Status_ChangePassUser': 'Passwort gesendet von { user } am { dateTime }',
    'GLOBAL.Text_AddLogo': 'Logo hinzufügen',
    'GLOBAL.Modal_Text_ResetFilters': 'Filter löschen',
    'ENTITY.Modal_Title_User': 'Nutzer',
    'ENTITY.Modal_Title_UserQuestion': 'Benutzerfragen',
    'ENTITY.Modal_Title_Dealer': 'Händler',
    'ENTITY.Modal_Title_Seller': 'Verkäufer',
    'ENTITY.Modal_Title_Post': 'Post',
    'ENTITY.Modal_Title_Document': 'Dokument',
    'ENTITY.Modal_Title_Comment': 'Kommentar',
    'ENTITY.Modal_Title_Company': 'Unternehmen',
    'MODAL.Button_Text_Choose': 'Auswählen',
    'MODAL.Button_Text_AddSeller': 'Verkäufer hinzufügen',
    'MODAL.Button_Text_AddUser': 'Nutzer Hinzufügen',
    'MODAL.Button_Text_AddUserDealer': 'Nutzer zu Händler hinzufügen',
    'MODAL.Button_Text_NotSelected': 'Nicht ausgewählt',
    'MODAL.Button_Text_NotAssigned': 'Nicht zugewiesaen',
    'MODAL.Button_Text_UserStatistic': 'Benutzerstatistiken',
    'MODAL.Button_Text_LogoSize': 'mind. 220 x 65',
    'MODAL.Button_Text_AddCompanies': 'Firmen hinzufügen',
    'MENUS.Label_Dashboard': 'Dashboard',
    'MENUS.Label_Companies': 'Firmen',
    'MENUS.Label_Users': 'Nutzer',
    'MENUS.Label_Dealers': 'Händler',
    'MENUS.Label_Posts': 'Posts',
    'MENUS.Label_Analytics': 'Statistik',
    'MENUS.Label_Collections': 'Kollektionen',
    'MENUS.Label_Hashtags': 'Hashtags',
    'MENUS.Label_Settings': 'Einstellungen',
    'MENUS_DROP.Label_Block': 'Gesperrt',
    'MENUS_DROP.Label_Active': 'Aktiv',
    'MENUS_DROP.Label_MyProfile': 'Mein Profil',
    'MENUS_DROP.Label_Logout': 'Abmelden',
    'MENUS_DROP.Label_SendPass': 'Passwort senden',
    'MENUS_DROP.Label_SendMail': 'Willkommens-E-Mail senden',
    'MENUS_DROP.Label_Edit': 'Bearbeiten',
    'MENUS_DROP.Label_Answer': 'Antworten',
    'MENUS_DROP.Label_View': 'Ansicht',
    'MENUS_DROP.Label_Delete': 'Löschen',
    'MENUS_DROP.Label_Mark': 'Markieren',
    'MENUS_DROP.Label_InProgress': 'in Bearbeitung',
    'MENUS_DROP.Label_Overview': 'Übersicht',
    'MENUS_DROP.Label_ViewPosts': 'Posts anzeigen',
    'MENUS_DROP.Label_ViewPost': 'Post anzeigen',
    'MENUS_DROP.Label_ViewFollowers': 'Followers anzeigen',
    'MENUS_DROP.Label_Download': 'Runterladen',
    'NAVBAR_PAGES.Label_Overview': 'Übersicht',
    'NAVBAR_PAGES.Label_Dealers': 'Händler',
    'NAVBAR_PAGES.Label_Dealer': 'Händler',
    'NAVBAR_PAGES.Label_Users': 'Benutzer',
    'NAVBAR_PAGES.Label_Sales': 'Verkäufe',
    'NAVBAR_PAGES.Label_Settings': 'Einstellungen',
    'NAVBAR_PAGES.Label_Counters': 'Zähler',
    'NAVBAR_PAGES.Label_EmailRules': 'E-Mail Regeln',
    'NAVBAR_PAGES.Label_Media': 'Medien',
    'NAVBAR_PAGES.Label_Attachments': 'Anhänge',
    'NAVBAR_PAGES.Label_Comments': 'Kommentar',
    'NAVBAR_PAGES.Label_Posts': 'Posts',
    'NAVBAR_PAGES.Label_Followers': 'Follower',
    'NAVBAR_PAGES.Label_UserQuestions': 'Benutzerfragen',
    'NAVBAR_PAGES.Label_AllComments': 'Alle Kommentare',
    'BREADCRUMBS.Label_Dealers': 'Händler',
    'BREADCRUMBS.Label_Dealer': 'Händler',
    'BREADCRUMBS.Label_Posts': 'Posts',
    'BREADCRUMBS.Label_Collections': 'Kollektionen',
    'BREADCRUMBS.Label_Hashtags': 'Hashtags',
    'BREADCRUMBS.Label_User': 'Nutzer',
    'BREADCRUMBS.Label_Users': 'Nutzer',
    'BREADCRUMBS.Label_Companies': 'Firmen',
    'BREADCRUMBS.Label_Company': 'Unternehmen',
    'BREADCRUMBS.Label_Analytics': 'Statistik',
    'BREADCRUMBS.Label_Sales': 'Verkäufe',
    'BREADCRUMBS.Label_Settings': 'Einstellungen',
    'BREADCRUMBS.Label_Counters': 'Zähler',
    'BREADCRUMBS.Label_Followers': 'Follower',
    'BREADCRUMBS.Label_Attachments': 'Anhänge',
    'BREADCRUMBS.Label_Comments': 'Kommentar',
    'BREADCRUMBS.Label_Media': 'Medien',
    'BREADCRUMBS.Label_Create': 'erstellen',
    'BREADCRUMBS.Label_Questions': 'Benutzerfragen',
    'SUMMARY.Label_Knys': 'knys',
    'SUMMARY.Label_Users': 'Nutzer',
    'SUMMARY.Label_Users_Visit' : 'Nutzer besuchen',
    'SUMMARY.Label_Posts': 'Posts',
    'SUMMARY.Label_Images': 'Bilder',
    'SUMMARY.Label_Documents': 'Dokumente',
    'SUMMARY.Label_Comments': 'Kommentare',
    'SUMMARY.Label_Collections': 'Kollektionen',
    'SUMMARY.Label_Hashtags': 'Hashtags',
    'SUMMARY.Label_Video': 'Video',
    'PAGES.Title_Dashboard': 'Dashboard',
    'PAGES.Title_Companies': 'Firmen',
    'PAGES.Title_Users': 'Nutzer',
    'PAGES.Title_Dealers': 'Händler',
    'PAGES.Title_Posts': 'Posts',
    'PAGES.Title_Collections': 'Kollektionen',
    'PAGES.Title_Hashtags': 'Hashtags',
    'PAGES.Title_Analytics': 'Statistik',
    'PAGES.Title_Settings': 'Einstellungen',
    'PAGES.Title_CreateDealer': 'Händler anlegen',
    'PAGES.Title_CreateCompany': 'Unternehmen erstellen',
    'PAGES.Title_CreateUser': 'Nutzer Anlegen',
    'PAGES.Title_AllComments': 'Alle Kommentare',
    'PAGES.Title_404': 'Sorry die Seite, die Sie besucht wollten will Sich Ihnen nicht zeigen',
    'PAGES.SubTitle_TopPosts': 'Bester Post',
    'PAGES.SubTitle_TopAuthors': 'Aktivster Nutzer',
    'PAGES.Text_NoTopAuthors': 'Es gibt noch keine Buchungsautoren',
    'PAGES.Text_NoTopPosts': 'Es gibt noch keine Top-Posten',
    'DASHBOARD.Chart_Title_AttachmentsCount': 'Anzahl Anhänge',
    'DASHBOARD.Chart_Title_CollectionsCount': 'Anzahl Kollektionen',
    'DASHBOARD.Chart_Title_CommentsCount': 'Anzahl Kommentare',
    'DASHBOARD.Chart_Title_HashtagsCount': 'Anzahl Hashtags',
    'DASHBOARD.Chart_Title_MediaCount': 'Anzahl Medien',
    'DASHBOARD.Chart_Title_PostsCount': 'Anzahl Posts',
    'DASHBOARD.Chart_Title_UsersCount': 'Anzahl Nutzer',
    'DASHBOARD.Chart_Title_UsersVisitCount': 'Anzahl Nutzerbesuche',
    'DASHBOARD.Chart_Count_Total': 'Summe',
    'DATE.Time_Zone': 'En-GB',
};

export default deutsch;
